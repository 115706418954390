@import '../../commons/common.scss';

.container {
  color: #888;
  font-family: sans-serif;
  height: calc(100vh - 4em) ; 
  text-align: center;
  width: 100%;
  vertical-align: middle;
  margin: 2em auto;

  .title {
    color: #555;
    font-size: 2em;
    font-weight: 400;
  }

  .text {
    margin: 0 auto;
    width: 280px;
  }
}

@media screen and (max-width: 280px) {
  .container {
    width: 95%;
    .text {
      width: 95%;
    }
    .title {
      font-size: 1.5em;
      margin: 0 0 0.3em;
    }
  }
}
