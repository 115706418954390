.model-list,
.add-model {
    width: 80%;
    height: 73.96%;
    background: #FFFFFF;
    border-radius: 3px;
    position: relative;

    .page-info {
        margin: 38px auto;
        text-align: center;
    }
}