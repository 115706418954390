.add-model {
    width: 40.6%;
    background: #FFFFFF;
    border-radius: 3px;


    .form-model-add {
        padding: 30px 30px 0;
    }

}