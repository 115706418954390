.edit-coffee {
    width: 70.41%;
    height: 80.8%;
    background: #FFFFFF;
    border-radius: 3px;
    overflow: auto;
    // position: relative;
    // top: 90px !important;
    // left: 50% !important;
    // transform: translateX(-50%);

    .content {
        margin-top: 25px;
        display: flex;
        justify-content: center;

        .addmaterial {
            padding: 0 25.76% !important;
        }

        .qrcode {
            width: 232px;
            height: 232px;
            background: #FFFFFF;
            border: 1px solid #DCDEE3;
            margin: 0px auto 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .tip {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang-SC;
                font-weight: 500;
                color: #9F7558;
                line-height: 20px;
            }
        }

    }

}