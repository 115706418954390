.ice-layout {
  .ice-layout-aside.custom-aside {
    background: #FFFFFF;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid #E6E7EB;
    position: relative;
    color: #1E1E1E;
    height: 100%;

    .collpse-button:hover {
      background: #F2F3F7;
    }

    .custom-menu {
      overflow-y: auto;
      width: 182px;
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 6px;
      box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

      .yiji {
        font-size: 1.25rem;

        .erji {
          font-size: 1rem;
        }
      }

      .next-menu-sub-menu {
        background: #FFFFFF;

        .next-menu-item {
          -webkit-font-smoothing: antialiased;
        }
      }

      .next-menu-item {
        color: #1E1E1E;
      }

      .next-selected,.next-opened {
        background: #FFF8F5;
        color: #FF794E;
      }

    }
  }
}
.ice-layout .next-nav.next-line .next-nav-item.next-menu-item:before{
  background-color: #9F7558 !important;
}