.edit-people {
    width: 30.6%;
    min-width: 528px;
    background: #FFFFFF;
    border-radius: 3px;
    overflow: auto;

    .content {
        width: 85%;
        margin: 25px auto 0px auto;
    }

}