.login {
    width: 100%;
    height: 100%;
    position: relative;


    .panel {
        width: 450px;
        height: 400px;
        left: 65%;
        top: 25%;
        transform: translateX(-50%);
        border-radius: 8px;
        position: absolute;

        .title {
            margin-top: 30px;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 2px;
            text-align: center;
        }

        .account-form {
            // margin-top: 48px;

            .next-input {
                border: 0;
                // border-radius: 0;
            }

            .item {
                width: 73.78%;
                margin: 48px auto 0;

                input {
                    border: 0;
                    outline: 0;
                    text-align: left;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    padding: 15px;
                    border: 1px solid #DCDEE3;
                    line-height: 20px;
                    border-radius: 0;
                    font-family: PingFangSC-Regular, PingFang SC;
                    border-radius: 3px;
                }

                input::-webkit-input-placeholder {
                    color: #999999;
                }

                input::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #999999;
                }

                input:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #999999;
                }

                input:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #999999;
                }
            }

            .login {
                width: 73.78%;
                height: 40px;
                margin: 36px auto 75px;

                .next-form-item-control {
                    height: 100%;
                }

                .btn {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    border: 1px solid rgba(0, 0, 0, 0);

                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    outline: none;
                    cursor: pointer;
                }
            }
        }

    }
}