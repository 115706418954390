// 基础类 样式；
// 使用规则： 在其他样式文件引入此公共样式文件即可 如： import './common.scss';

//样式设定包括 
// 1. 设计规范中所指定的基础样式，如: 字体，圆角，背景，阴影等
// 2. 页面 标签元素样式设定，如: html/body, 等
// 3. 项目中使用的全局 选择器样式

/*
* # 变量命名规则：
* 1. 统一采用驼峰式，使用下划线‘_’ 拼接前缀, 语义化命名，如： $main_color: red;
* 2.禁止使用 - 拼接的方式，易造成变量覆盖

* # 代码规范
* 1. 选择器 与花括号之间 必须包含一个空格
* 2. 键名与冒号之间不包含空格, 键值与冒号之间必须包含一个空格
* 3. 每条规则之间必须包含一个空行
* 4. 逗号前不允许有空格, 逗号后必须包含一个空格
* 5. > + ~ 等选择器前后包含一个空格
* 6. 多个并行选择器,须换行
* 7. 选择器 避免使用标签名
* 8. 层级(z-index)禁止随意设置，页面弹窗、气泡为最高级（最高级为999）；普通区块为10-90内10的倍数；同层的多个元素，在该层级内使用相同的 z-index 或递增。
* 9. 禁止使用 !important (特殊情况除外，如覆盖第三方插件中的样式等)
*/

// 主色
// $main_color:#9F7558; // rgb(255, 110, 2);

// .main-color {
//   background-color: $main_color !important;
// }


// 订单状态的颜色
// 重新审核 审核失败
.review,
.faild {
  color: #F3545E;
}

// 审核中 未支付
.audit,
.nopay {
  color: #FF8A65;
}

// 投放中
.put {
  color: #4C9EFF;
}

// 已完成
.complate {
  color: #333333;
}







/* 样式初始化 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#ice-container {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'PingFang Bold';
  src: url('../static/font/PingFang\ Bold.ttf');
}

@font-face {
  font-family: 'PingFang ExtraLight';
  src: url('../static/font/PingFang\ ExtraLight.ttf');
}

@font-face {
  font-family: 'PingFang Heavy';
  src: url('../static/font/PingFang\ Heavy.ttf');
}

@font-face {
  font-family: 'PingFang Light';
  src: url('../static/font/PingFang\ Light.ttf');
}

@font-face {
  font-family: 'PingFangSC-Medium';
  src: url('../static/font/PingFang\ Medium.ttf');
}

@font-face {
  font-family: 'PingFang Regular';
  src: url('../static/font/PingFang\ Regular.ttf');
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Medium;
  font-family: PingFangSC-Regular, PingFang SC;
  /* font-family: 'PingFang Regular'; */
  /* font-family:'PingFang Light', 'PingFang Medium', 'PingFang Regular','PingFang Heavy','PingFang Bold','PingFang ExtraLight'; */
}

li,
ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.theme-color {
  color: #FF794E;
}


.next-dialog {
  width: 23.64%;
}


//公共布局
.header {
  .search-item {
    display: flex;
    align-items: center;

    .search-label {
      // width: 58px;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #666666;
      margin-right: 13px;
    }
  }
}

.next-range-picker-trigger {
  display: flex;
  align-items: center;
}

.content {
  margin-top: 15px;

  .operation {
    button {
      margin-right: 20px;
      color: #5584FF;
    }
  }

  .statusText {
    color: #FF8A65;
  }

  th {
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  .next-table-body {

    // height: 500px !important;
    // max-height: none !important;
    // padding-bottom: 10px;

  }

  .page-info {
    margin-top: 38px;
    text-align: center;
  }
}

.date-picker {
  .next-icon::before {
    content: "\E621";
  }
}

.next-table-body {
  & div:nth-child(1) {
    height: auto !important;
    overflow: visible !important;
  }
}