.header {
    position: relative;

    .title {
        margin-top: 24px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1E1E1E;
        line-height: 22px;
    }

    .close {
        position: absolute;
        right: 41px;
        top: 0px;
        color: #999999;
        cursor: pointer;
    }
}
