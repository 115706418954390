// body {
//   -webkit-font-smoothing: antialiased;
//   font-family: 'SourceHanSans-Medium',
// }

.ice-layout {
  width: 100%;
  // min-width: 1200px;
  height: 100%;

  .next-nav.next-line .next-nav-item.next-menu-item:before {
    background-color: #FF6E02;
    width: 2px;
    right: 1px;
  }

  .ice-layout-main {
    position: relative;
    background: #f2f2f2;
    padding: 0;
    display: flex;
    flex-direction: row;
    height: calc(100% - 70px);

    .ice-layout-header {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }

    .main-container {
      width: 100%;
      height: 100%;
      padding: 24px;
      overflow: scroll;
      // height: calc(100% - 62px);
      position: relative;

      .all-title {
        padding-bottom: 20px;
        display: flex;
        align-items: center;

        .line {
          width: 3px;
          height: 24px;
          background: #FF8A65;
          border-radius: 2px;
        }

        .title-text {
          font-size: 20px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #333333;
          line-height: 28px;
          margin-left: 12px;
        }
      }

    }
  }
}


.next-overlay-wrapper .next-overlay-backdrop {
  position: absolute;
}

.next-date-picker-input {
  width: auto;
}

// .next-pagination .next-pagination-item.next-current {
//   background: #FF6E02 !important;
//   border-radius: 30px !important;
// }

// .next-pagination .next-pagination-item {
//   border-radius: 30px !important;
// }

// .next-table-group .next-table-group-header td {
//   background-color: #fff !important;
// }

// .next-table-group .next-table-body {
//   margin-top: 0 !important;
// }

// .btn-in {
//   background-image: linear-gradient(90deg, #FFA10D 0%, #FF6E02 100%);
//   box-shadow: 0 2px 6px 0 rgba(230, 24, 24, 0.30);
//   border-radius: 30px;
//   float: right;
// }

.searchWidth {
  width: 300px;
}


// .next-search .next-input{
//   border-top:1px solid #ccc;
//   border-left:1px solid #ccc;
// }
// 分组表格下边距为0
.next-table-group .next-table-body table {
  margin-bottom: 0 !important;

  .next-table-group-header td {
    background-color: #eee !important;
  }
}

// 分组
// .next-table-row.last td{
//   // border-bottom-width:0px;
// }
.forBottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.next-btn.next-large {
  padding: 0 25px !important;
}

.FormCol {
  display: flex;
  align-items: center;
  //margin-bottom: 1rem;
}

.dialogButton {
  border-radius: 30px;
  //width: 66px;
  height: 40px;
}

.next-input.next-medium {
  width: 100%;
}

.EditBtn {
  background-image: linear-gradient(90deg, #FFA10D 0%, #FF6E02 100%);
  box-shadow: 0 2px 6px 0 rgba(230, 24, 24, 0.30);
}

.OutBtn {
  background-image: linear-gradient(90deg, #05BEFE 0%, #05A0FE 100%);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  margin-left: 40px !important;
}

.xis {
  float: right;
  margin: -3.5rem 5rem 0 0;
  border-radius: 5px !important;
}

.tkInput.next-input.next-large {
  height: 30px;
  line-height: 30px;
  border-radius: 0;
}

.tkInput.next-input.next-large input {
  height: 30px;
  line-height: 30px;
}

.tkSelect {
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.tkSelect .next-input.next-large.next-select-inner {
  border-radius: 0;
  height: 30px;
  line-height: 30px;
}

.tkSelect .next-input.next-large .next-input-text-field {
  height: 30px;
  line-height: 30px;
}

.data_p {
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.data_p .next-input.next-large {
  width: 100%;
  height: 30px !important;
  border-radius: 0;
}

.data_p .next-input.next-large input {
  height: 30px !important;
  line-height: 30px;
}

.ReturnBtn {
  margin-left: 40px !important;
}

// 上传组件文字不可选中
.next-upload-card .next-upload-text {
  outline: none;
}

// tree 字号大小
.next-tree-node-label {
  font-size: 16px;
  font-weight: bold;
}

.Select {
  float: left;
  width: calc(100% - 100px);
  height: 38px;
  max-width: 300px;
  margin: 10px 0;
}

;

.DatePicker {
  width: calc(100% - 100px);
  // minWidth: 130px;
  float: left;
  max-width: 300px;
  margin: 10px 0;
}

;

.SelectLabel {
  font-size: 14px;
  line-height: 40px;
  float: left;
  margin: 10px 10px 10px 0;
  padding: 0;
  color: #666;
  text-align: right;
  min-width: 60px;
}

;

/*滚动条整体样式*/
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px !important;
// }

/*滚动条滑块*/
// ::-webkit-scrollbar-thumb {
//   border-radius: 6px;
//   box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
//   background: rgb(245, 150, 67);
//   background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
//   height: 2px;
//   width: 2px;
// }

/*滚动条轨道*/
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 1px rgba(0, 0, 0, .1);
//   border-radius: 10px;
//   background: rgba(225, 225, 225, .625);
//   margin-right: -10px;
// }

// 额外展开表格的样式

// .next-table-expanded-ctrl {
//   outline: none;
//   cursor: pointer;
// }

// #VipBuyRecordTable.next-table td.next-table-cell .next-table-cell-wrapper {
//   padding: 8px !important;
// }