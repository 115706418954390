.header-container {
  width: 100%;
  height: 70px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  // flex-direction: row-reverse;
  z-index: 100;

  .theme-color {
    font-size: 20px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    line-height: 28px;
  }

  .userinfo {
    display: flex;
    align-items: center;
  }

  .user-name {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
    margin-right: 40px;
  }
}